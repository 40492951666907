export default function () {
  const { $sitewideConfig, $geolocation } = useNuxtApp()
  const config = useRuntimeConfig()
  const route = useRoute()

  const state = useState<string | string[]>('disableIntegrations')

  type Integration = keyof {
    [key in IntegrationKey as key extends `${infer K}Enabled` ? K : never]: IntegrationKey
  }

  /**
   * Detects if the integration is enabled using configs and the disable query string
   * @returns true if the integration is enabled
   */
  function isIntegrationEnabled(integration: Integration) {
    const disableByQuery = route.query['disableIntegration']
    if (disableByQuery) state.value = disableByQuery.toString()

    if (isDisabledInDev(integration) || isDisabledInPreview(integration) || isDisabledByLocation(integration))
      return false

    // disabled via site config
    if ($sitewideConfig.config && $sitewideConfig.config[`${integration}Enabled`] === false) return false

    // disabled by query string
    if (state.value === 'all' || state.value?.includes(integration)) return false

    return true
  }

  // temp bandaid, disable non-essential junk for CA
  function isDisabledByLocation(integration: Integration) {
    if (!$geolocation || $geolocation.edgeRegion !== 'CA') return false
    if (['segment', 'wisepops', 'amplitude'].includes(integration)) return true
    return false
  }

  function isDisabledInDev(integration: Integration) {
    if (!isDev()) return false

    switch (integration) {
      case 'bugsnag':
      case 'forter':
      case 'segment':
      case 'speedcurve':
        return true
    }

    return false
  }

  // It's possible this could return false instead of true if they are in preview and go to another page and THAT page calls this -- meh, they aren't supposed to be navigating in preview anyway
  // We can update whenever the storyblok integration is created if really needed
  function isDisabledInPreview(integration: Integration) {
    if (!route.query['_storyblok']) return false

    // detect preview?
    switch (integration) {
      case 'forter':
      case 'liveperson':
      case 'wisepops':
      case 'segment':
      case 'speedcurve':
        return true
    }
    return false
  }

  /**
   * Detects if the container is running in production
   * @returns true if the container is using production configs
   */
  function isProduction() {
    return config.public.mode === 'production'
  }

  /**
   * Detects if the server is running in dev mode
   * @returns true if the server is running on your local dev env
   */
  function isDev() {
    return import.meta.env.MODE !== 'production'
  }

  function isRealtruckBrand(brandName: string) {
    if (!brandName || !$sitewideConfig.config.madeByRealTruckEnabled) return false

    return !!(
      $sitewideConfig.config.madeByRealTruckBrands?.data.includes(brandName) ||
      $sitewideConfig.config.madeByRealTruckOnlineOnlyBrands?.data.includes(brandName)
    )
  }

  return {
    isIntegrationEnabled,
    isProduction,
    isDev,
    isRealtruckBrand,
  }
}
