import type { Experiment } from '@amplitude/experiment-js-client'

export default defineNuxtPlugin(() => {
  const { isIntegrationEnabled } = useUtils()
  if (!isIntegrationEnabled('amplitude')) return

  // This plugin is set to 02.client.ts because it is dependant on both $segment and $cognito.
  const { $sitewideConfig, $uiEvents, $segment, $cognito } = useNuxtApp()
  const log = useLogger('AMPLITUDE')
  let experiment: ReturnType<typeof Experiment.initialize>
  let isInitialized = false
  let isAmplitudeLoaded = false

  const loadAmplitude = async () => {
    if (isAmplitudeLoaded) return

    try {
      const { Experiment } = await import('@amplitude/experiment-js-client')
      experiment = Experiment.initialize($sitewideConfig.config.amplitudeKey, {
        automaticExposureTracking: false,
        fetchOnStart: true,
        exposureTrackingProvider: {
          track: (exposure) => {
            // only track the exposure if they are in a Variant
            if (!exposure.variant) return
            $uiEvents.$emit('amplitudeExposure', exposure)
          },
        },
      })
      isAmplitudeLoaded = true
    } catch (error) {
      log.error('initialize', { error })
    }
  }

  const getUser = async (userId?: string) => {
    if (!userId) {
      const user = await $cognito.getUserAttributes()
      userId = user?.id
    }

    // When testing locally comment this line out and set it manually
    const deviceId = await $segment.getAnonId()
    // example: const deviceId = '9f63ebce-e647-498e-ad8e-2b195507eec2'

    return {
      user_id: userId,
      device_id: deviceId,
    }
  }

  const fetchExperiment = async (userId?: string) => {
    try {
      await loadAmplitude()
      const user = await getUser(userId)
      await experiment.fetch(user)
    } catch (error) {
      log.error('fetch', { error })
    }
  }

  const onReady = async () => {
    if (isInitialized) return
    isInitialized = true
    await loadAmplitude()
    try {
      const user = await getUser()
      await experiment.start(user)
    } catch (error) {
      log.error('start', { error })
    }

    $uiEvents.$on('cognitoSignUp', async (userId) => await fetchExperiment(userId))
    $uiEvents.$on('cognitoSignIn', async () => await fetchExperiment())
  }

  return {
    provide: {
      amplitude: {
        async track(experimentId: string) {
          if (!isIntegrationEnabled('cognito') || !isIntegrationEnabled('segment')) return

          try {
            await loadAmplitude()
            if (!experiment) return
            experiment.exposure(experimentId)
          } catch (error) {
            log.error('track exposure', { error })
          }
        },
        async getVariant(experimentId: string) {
          if (!isIntegrationEnabled('cognito') || !isIntegrationEnabled('segment')) return
          try {
            await loadAmplitude()
            if (!experiment) return
            await onReady()
            return experiment.variant(experimentId)
          } catch (error) {
            log.error('get variant', { error })
          }
        },
      },
    },
  }
})

/**
     * Example Variant Payload
     {
        "data":
        [
            {
                "hide": "playground-h1",
                "devices": [
                    "mobile",
                    "tablet"
                ]
            },
            {
                "show": "playground-h2",
                "devices": [
                    "desktop",
                    "tablet"
                ]
            }
        ]
     }
     *
  */
